import React, { Component, Fragment } from 'react';
import { Api } from 'shared/api';
import axios from "axios";
import Config from 'config';
import { translate } from "react-i18next";
import { Form } from 'react-form';
import Iframe from 'react-iframe';
import Modal from 'components/modal/index.jsx';
import { Validation } from 'shared/Validation';
import Classnames from 'classnames';
import { WithDependency } from 'shared/DependencyContext';
import IconCheck from 'static/home/icons/check.svg';
import HintTextField from 'components/forms/hint-text-field';
import Spinner from 'components/spinner';

import './index.scss';

let staticFileAlias = '';
if (!window.location.hostname.includes('localhost') && !window.location.hostname.includes('gamma.evryplace')) {
  staticFileAlias = window.location.origin + '/home/';
} else {
  if (window.location.pathname.includes('/p/')) {
    staticFileAlias = '../';
  }
}

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socialsLoading: !props.lagardere,
    };
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleFbLogin = this.handleFbLogin.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (!this.props.loginOpened && nextProps.loginOpened) {
      window.scrollTo(0,0);
    }
    if (nextProps.user.errorCode && nextProps.user.errorCode !== 'EMAIL_ALREADY_REGISTERED') {
      if (!this.props.user.errorCode && nextProps.user.errorCode) {
        let message = this.props.t('server.' + nextProps.errorCode);
        this.props.notifications.add(message);
      }
    }
  }
  componentDidMount () {
    setTimeout(() => {
      this.setState({ socialsLoading: false });
    }, 2000);
    window.document.addEventListener('googleLoginEvent', this.handleGoogleLogin, false);
    window.document.addEventListener('fbLoginEvent', this.handleFbLogin, false);
  }
  componentWillUnmount() {
    window.document.removeEventListener('googleLoginEvent', this.handleGoogleLogin);
    window.document.removeEventListener('fbLoginEvent', this.handleFbLogin);
  }
  handleGoogleLogin(e) {
    axios.post(Api.auth.google, e.detail).then(() => {
      this.props.fetchUser();
      this.props.onClose();
    },
    error => console.log(error));
  }
  handleFbLogin() {
    var fbLoginUrl = Api.auth.facebook;
    var fbRedirectUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=768848760910245&redirect_uri=${window.location.origin + fbLoginUrl}&state=123&scope=email,public_profile`;
    window.open(fbRedirectUrl, '_self');
  }
  handleMicrosoftLogin() {
    var microsoftLoginUrl = Api.auth.microsoft;
    var microsoftRedirectUrl = `https://login.microsoftonline.com/1b21c76d-1560-41d5-a2ad-52e60ec27cee/oauth2/v2.0/authorize?%20client_id=c1e2f386-e935-4922-892c-39cc7f243122%20&redirect_uri=${window.location.origin + microsoftLoginUrl}&scope=c1e2f386-e935-4922-892c-39cc7f243122/.default&response_type=code&state=96cbeb3d1624fe852586436f887203992f2bea37`;
    window.open(microsoftRedirectUrl, '_self');
  }
  onSubmit(values) {
    let { login, password } = values;
    login = login.replace(/\s/g, '');
    this.props.onSubmit({data: {login, password }});
  }
  render() {
    const { t, onClose, forceLogin, user, cantLoginButton = true, socialButtons = true, registerButton = true, lagardere = false } = this.props;
    const { socialsLoading } = this.state;
    let status, // Modal status initializations
      errorStatus,
      loginOpened;

    if (user && user.state == 'loggedIn') {
      status = <div className="input-row status"><IconCheck /><p>{t('login.successMsg')}</p></div>;

      if (window.location.pathname === '/login') {
        setTimeout(() => {
          window.open(window.location.origin, '_self');
        }, 500);
      }
    }

    if (user && user.errorMsg) {
      errorStatus = <div className="input-row error-status"><p>{this.props.user.errorMsg}</p></div>;
    }

    loginOpened = this.props.open === true || forceLogin;

    return (
      <Modal open={loginOpened} onClose={onClose} className={`login-modal${lagardere ? ' lagardere' : ''}`}>
        <Form onSubmit={(submittedValues) => {
          this.onSubmit(submittedValues);
        }} validateOnSubmit={true}>
          {formApi => (
            <form onSubmit={formApi.submitForm}>
              <h3 dangerouslySetInnerHTML={{ __html: t('login.header') }} />
              <HintTextField
                hintText={t('login.emailPH')}
                field="login"
                id="login"
                validate={Validation.email}
                disabled={false}
              />
              <HintTextField
                hintText={t('login.passwordPH')}
                field="password"
                id="password"
                type="password"
                disabled={false}
              />
              <button type="submit" className="login-modal__button">{t('login.loginBtn')}</button>
              {status}
              {errorStatus}
              {cantLoginButton && <a onClick={this.props.openResetPasswordModal}>{t('login.cannotLogin')}</a>}
            </form>
          )}
        </Form>
        {socialButtons && (
          <Fragment>
            <div className="social__label">{t('login.orLabel')}</div>
            <div className={Classnames("social__buttons", socialsLoading ? "hidden" : '')}>
              {lagardere ? (
                <Fragment>
                  <div id="microsoftBtn" onClick={this.handleMicrosoftLogin}>
                    <img src={`${staticFileAlias}static/socials/microsoft.svg`} />
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div id="customBtn" className="customGPlusSignIn">
                    <span className="icon"></span>
                    <span className="buttonText">Google</span>
                  </div>
                  <Iframe url={`${staticFileAlias}static/socials/socials.html`} frameBorder={0} height="80px" width="160px" />
                  <Iframe url={`${staticFileAlias}static/socials/fb2.html`} frameBorder={0} height="80px" width="180px" />
                </Fragment>
              )}
            </div>
            {socialsLoading && <Spinner style={{ borderTop: '1.1em solid gray' }} />}
          </Fragment>
        )}
        {socialButtons && registerButton && (
          <hr />
        )}
        {registerButton && (
          <Fragment>
            <div className="social__label">{t('login.createAccount')}</div>
            <button className="login-modal__button register" onClick={this.props.openRegistrationModal}>{t('login.registration')}</button>
          </Fragment>
        )}
      </Modal>
    );
  }
}

export default translate('translations')(WithDependency(LoginModal));
